/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    em: "em",
    h2: "h2",
    a: "a",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "typescript"
  }, "TypeScript"), "\n", React.createElement(_components.p, null, "Theme UI is written in TypeScript."), "\n", React.createElement(_components.p, null, "While most APIs in Theme UI should ", React.createElement(_components.em, null, "just work"), " in TypeScript,\nthere are a few advanced use cases which will differ slightly.\nThis guide is intended to cover those use cases."), "\n", React.createElement(_components.h2, {
    id: "exact-theme-type"
  }, "Exact theme type"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "/theme-spec"
  }, React.createElement(_components.code, null, "Theme"), " type"), " represents all possible themes."), "\n", React.createElement(_components.p, null, "It might be what you need when you're writing a library of reusable components\nor an app where the theme object is provided by the user and kept in the database.\nHowever, it's not the most convenient way to think about the theme,\nwhen building a blog or a landing page."), "\n", React.createElement(_components.p, null, "To know the exact type of your particular theme on type level,\nyou can use an identity \"constructor\" function to narrow the type."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "const makeTheme = <T extends Theme>(t: T) => t\n\nconst theme = makeTheme({\n  colors: {\n    background: 'white',\n    text: 'black',\n    blue: {\n      light: '#187abf',\n      dark: '#235a97',\n    },\n  },\n})\n\nexport type ExactTheme = typeof theme\n\n// No error\nconst lightBlue = theme.colors.blue.light\n")), "\n", React.createElement(_components.p, null, "You can then reexport ", React.createElement(_components.code, null, "useThemeUI"), " hook with narrowed type."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "import { ThemeUIContextValue } from 'theme-ui'\n\ninterface ExactContextValue extends Omit<ThemeUIContextValue, 'theme'> {\n  theme: ExactTheme\n}\n\nexport const useTheme = (useThemeUI as unknown) as () => ExactContextValue\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, React.createElement(_components.a, {
    href: "https://codesandbox.io/s/theme-ui-typescript-tips-yh8u1?file=/src/exact-theme-type.ts"
  }, "Try it on CodeSandbox."))), "\n", React.createElement(_components.h2, {
    id: "accessing-optional-properties"
  }, "Accessing optional properties"), "\n", React.createElement(_components.p, null, "Because all properties of ", React.createElement(_components.code, null, "Theme"), " are optional,\naccessing them requires usage of ", React.createElement(_components.a, {
    href: "/api#get"
  }, React.createElement(_components.code, null, "get"), " function"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "/** @jsxImportSource theme-ui */\nimport { Theme } from 'theme-ui'\n\nconst theme: Theme = {\n  space: [0, 8, 16, 32, 64, 128, 256],\n  sizes: [0, 8, 16, 32, 64, 128, 256],\n}\n\n// Type error on `theme.space` and `theme.sizes`.\n// Object is possibly 'undefined'.ts(2532)\nreturn <div sx={{ size: (t) => t.space[3] + t.sizes[5] }} />\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "/** @jsxImportSource theme-ui */\nimport { Theme, get } from 'theme-ui'\n\n// No error\nreturn <div sx={{ size: (t) => get(t, 'space.3') + get(t, 'sizes.5') }} />\n")), "\n", React.createElement(_components.p, null, "Properties of scales can be accessed with optional chaining.\nValues under numeric keys can be accessed with bracket notation."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "/** @jsxImportSource theme-ui */\n\nconst parse = (x: unknown) => parseInt(String(x))\n\nreturn (\n  <div sx={{\n    size: (t) => parse(t.space?.[3]) + parse(t.sizes?.[5]) }}\n  />\n)\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, React.createElement(_components.a, {
    href: "https://codesandbox.io/s/theme-ui-typescript-tips-yh8u1?file=/src/App.tsx"
  }, "Try it on CodeSandbox."))), "\n", React.createElement(_components.h2, {
    id: "additional-properties-in-the-theme"
  }, "Additional properties in the theme"), "\n", React.createElement(_components.p, null, "If you need additional properties in theme object,\nyou can add them with ", React.createElement(_components.a, {
    href: "https://www.typescriptlang.org/docs/handbook/declaration-merging.html#merging-interfaces"
  }, "declaration merging"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "interface MySyntaxHighlightingTheme {\n  foreground: string\n}\n\ndeclare module 'theme-ui' {\n  interface Theme {\n    syntaxHighlighting: MySyntaxHighlightingTheme\n  }\n}\n\nconst theme: Theme = {\n  syntaxHighlighting: {\n    foreground: '#222',\n  },\n}\n\nconst syntaxHighlighting = theme.syntaxHighlighting\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, React.createElement(_components.a, {
    href: "https://www.typescriptlang.org/v2/en/play?#code/JYWwDg9gTgLgBAbzgFQBYFMTrgXzgMyghDgHIYMsBaAV2FIG4AoJ4AOxnSnwEMBjbAFkAngGVhHHgA8AEsADmqADYLUMdvLSZsCJnALR08ojTYATAFxwAzjCgbmOFmfR8lPKNhAQzNJdnJKdFp6RD04dk5ufmwtLDD9fWsJGGk5RRVFdTZ5KxFxSVlVTLUNOPRwpycmPgg2WzgKbStyuABeBJsUtOLVbNzO-XxDYwhTSzIAYgAmWdIAGkqmHGYauobkwvTlPo12xqCAOk3UoozdnLX6+C4iKH2mrGPhGDYe86yNJiA"
  }, "Try it in TypeScript Playground."))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
