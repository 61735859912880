"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    img: "img",
    code: "code",
    pre: "pre",
    h2: "h2"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uicss",
      children: "@theme-ui/css"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://bundlephobia.com/package/@theme-ui/css",
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://badgen.net/bundlephobia/minzip/@theme-ui/css",
          alt: "Minified Size on Bundlephobia"
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "@theme-ui/css"
      }), " contains the framework-agnostic core logic of Theme UI. It lets\nyou write style objects with responsive, theme-aware ergonomic shortcuts."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "npm i @theme-ui/css @emotion/react\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "usage",
      children: "Usage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { css as transformStyleObject } from '@theme-ui/css'\nimport { css as createClassName } from '@emotion/css'\n\nconst root = document.getElementById('root')\n\n/** @type {import(\"@theme-ui/css\").Theme} */\nconst theme = {\n  colors: {\n    text: '#222',\n  },\n  fonts: {\n    mono: 'monospace',\n  },\n  space: {\n    sm: '1rem',\n    md: '2rem',\n  },\n}\n\nconst styles = transformStyleObject({\n  padding: ['sm', 'md'],\n  border: ({ colors }) => `2px solid ${colors.text}`,\n  h1: {\n    fontFamily: 'mono',\n    color: 'text',\n  },\n})(theme)\n\nroot.classList.add(createClassName(styles))\nroot.innerHTML = `\n<h1>You can use <code>@theme-ui/css</code> in Vanilla JS!</h1>\n`\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://codesandbox.io/s/theme-ui-css-framework-agnostic-2c0ue?file=/src/index.js",
        children: "See the snippet above on CodeSandbox"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;