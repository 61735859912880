"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    hr: "hr",
    h1: "h1",
    h2: "h2",
    pre: "pre",
    code: "code"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_components.p, {
      children: ["⚠ ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "This package is no longer maintained as its dependencies (postcss-rtl) are\nnot maintained. Feel free to copy the code to your project — it's just 44 LoC."
      }), "\n⚠"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uitachyons",
      children: "@theme-ui/tachyons"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Generate static CSS for use outside of React with Theme UI and Tachyons."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "why",
      children: "Why?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Though Theme UI comes with its own implementation, project constraints might\nrequire pure utility CSS output. This library allows you to leverage a Theme UI\ntheme that is used elsewhere and transforms it to the configuration that\nTachyons expects. The best of both worlds."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "installation",
      children: "Installation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "npm i @theme-ui/tachyons\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "usage",
      children: "Usage"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Transform your Theme UI compliant theme config with the library:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// scripts/generate-tachyons-css.js\nconst toTachyons = require('@theme-ui/tachyons')\nconst tachyonsGenerator = require('tachyons-generator')\nconst theme = require('../theme')\n\nmodule.exports = async () => {\n  const { css } = await tachyonsGenerator(toTachyons(theme)).generate()\n\n  return css\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;