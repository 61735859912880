"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    em: "em",
    a: "a",
    h1: "h1",
    h2: "h2",
    code: "code"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Demo Content"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["From ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://jxnblk.com/blog/interoperability",
          children: "https://jxnblk.com/blog/interoperability"
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      id: "interoperability",
      children: "Interoperability"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Back in early 2013, I was working at a small startup in San Francisco called Stitch Fix, alongside Adam Morse and a handful of others.\nWe were designing and building out early versions of their marketing pages and some internal tools.\nI had the word designer in my title, and until that point in my career, I'd not shipped much production code for work.\nI learned Flash in college and taught myself HTML and CSS on the side over the years, using it for small side projects and design prototypes for user research, but had never used Git and knew next-to-nothing about JavaScript."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "We were both enamored by the work that people like Nicole Sullivan and Nicolas Gallagher were doing with Object-Oriented CSS and stealing lots of ideas for what we were working on.\nAfter we left the company, I decided to move back to the east coast and work for Kickstarter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In late 2013, I'd taken some of the ideas Adam and I were talking about at the time, and some of the problems we were working on at Kickstarter (involving a 1MB+ CSS bundle), and released the first version of Basscss.\nAround the same time, Adam released Tachyons.\nWe both continued to develop each project separately and never landed on a common API for the two libraries."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "naming-things-is-hard",
      children: "Naming things is hard"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In hindsight, I wish I'd pushed forward with Tachyons naming conventions, but at the time, I don't think my team would have gone for it.\nI had changed ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "btn"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "button"
      }), " because the designers & developers I worked with prefered \"human readable\" naming conventions.\nThere's nothing objectively more or less \"human readable\" between ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "btn"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "button"
      }), ", but I do understand the concern and think it's valuable to side with the team your working with in situations like this.\nBut there's no reason Basscss, as an open source library, had to adhere to the same conventions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The real tragedy here in the divergent naming conventions is that if you've started building an application with Basscss,\nbut then want to upgrade to something more fully-featured like Tachyons, you'll have to do a lot of manual work to migrate.\nEssentially, HTML templates written with either of these libraries isn't as portable as if we'd used a standard syntax, for example inline styles.\nToday's tools would make this a lot easier, using type checking, unit tests, and perhaps even AST parsing, but it would still create a barrier."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "interoperability"
      }), " between these two libraries suffered because\n", (0, _jsxRuntime.jsx)(_components.em, {
        children: "what"
      }), " they did, was different from ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "how"
      }), " they did it."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Around the same time, Etsy adopted a similar approach with slightly different naming conventions, and as the years passed,\nso did BuzzFeed Solid, GitHub Primer, and Bootstrap – and now there are other open source libraries doing the same thing with, again, new naming conventions.\nI don't mean to call these projects out in a bad way – I think they took (what I think was) a good idea and made it work for their team."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "learning-from-the-past",
      children: "Learning from the past"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The paradigm of atomic/functional/utility CSS has passed.\nI don't think there are any problems left to solve in this space, and, in my opinion, Tachyons has done the best job at it.\nIt's still a valid approach to styling an application,\nbut if you're using a modern front-end stack with React,\nI wouldn't recommend using a CSS library like these to build out an application.\nLibraries like ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://styled-components.com",
        children: "Styled Components"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://emotion.sh",
        children: "Emotion"
      }), " are tools much better suited to the job and will save you a lot of headache in the long run."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "lets-not-do-this-again",
      children: "Let's not do this again"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Why am I talking about approaches to CSS from five years ago?\nCall me naive, but I like to think that humans are capable of learning from their past mistakes.\nA lot of modern front-end libraries are starting to settle on more standard, more interoperable APIs that help reduce lock-in.\nTake React and Preact, or Styled Components and Emotion.\nThese libraries share virtually the same API, which means teams can easily migrate from one to another in the span of an afternoon.\nThis is great thing, and it allows developers to start thinking at a higher level of abstraction."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Styled System is one small attempt at a higher level of abstraction on top of the current CSS-in-JS libraries.\nIt's completely decoupled from Styled Components, Emotion, and even React itself.\nFor the most part, Styled System uses CSS property names as React component props,\nwhich is something that other libraries do as well and doesn't require much additional learning if you already know ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "some"
      }), " CSS.\nI think this is great.\nSome libraries have even re-implemented the core of Styled System for various reasons,\nwhich I think is fine and helps encourage exploration."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Where I see differences start to arise is at the theme definition level.\nEven outside of React context-based theming, a lot of React applications will store global style constants in a common module.\nSomething I'm starting to notice is that there are no standard conventions for what that module contains or how its structured,\nbut all of them seem to be doing the same thing, in a slightly different way."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "design-system-tokens",
      children: "Design System Tokens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["I suspect a lot of the tooling for styling applications would benefit from having a standard theming format for storing these values.\nJina Anne pioneered the concept of ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "design system tokens"
      }), " [^1] years ago,\nand a lot of teams have successfully adopted approaches like this, which I think is fantastic.\nIf you're already familiar with the idea of ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "design system tokens"
      }), ", you're one step closer to seeing the value in a standard theming format."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Design system tokens are meant to be flexible and work cross-platform, which means different teams,\ndifferent implementations, and different libraries will name things differently.\nThis is where this specification would fit in.\nA lot of interoperability could be realized,\nif we all, for example, named our color palette ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "colors"
      }), " and named the font sizes we use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fontSizes"
      }), ".\nWhat you do beyond that and what data format you use to store these values, is up to you.\nIt's trivial to convert JSON to ES modules to YAML or even TOML, if that's your thing.\nIt's also just a data structure, so transforming between other data structures (e.g. design tools or a GraphQL API) should also be possible.\nThis standard also wouldn't try to solve the extremely complex problems of how to name the colors themselves."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "a-theme-specification",
      children: "A Theme Specification"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If I haven't lost you by now, and if this interests you,\nI've started a rough idea of what this could look like here: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://styled-system.com/theme-specification",
        children: "Theme Specification"
      }), ".\nImagine if the entire ecosystem of open source React UI components adhered to a common naming convention for theming,\nwhile still allowing you to choose the CSS-in-JS library of your choice.\nAny new UI component you add to your application,\ncould instantly pick up the values from your theme and mimic the look and feel of the rest of your application,\nwithout needing to write any custom CSS.\nI think that could be pretty cool."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ultimately this specification should be completely decoupled from the Styled System library,\nand I can move this to a more neutral location in the near future,\nbut this seemed like a logical place to start this discussion."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Let me know what you think on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://mobile.twitter.com/jxnblk/status/1107726037805424641",
        children: "Twitter"
      }), "\nor by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/styled-system/styled-system/issues",
        children: "opening an issue"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "[^1]:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Design system tokens are a fantastic abstraction. They try to solve the problem of sharing a brand's core style values cross-platform,\nwhereas this specification is (initially) primarily focused on CSS/JS development – i.e. a potential ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "output"
      }), " from design tokens.\nDesign system tokens and this theme specification should be completely compatible with each other and decoupled from one another."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;