"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_components.table, {
    children: [(0, _jsxRuntime.jsx)(_components.thead, {
      children: (0, _jsxRuntime.jsxs)(_components.tr, {
        children: [(0, _jsxRuntime.jsx)(_components.th, {
          children: "Key"
        }), (0, _jsxRuntime.jsx)(_components.th, {
          children: "Description"
        })]
      })
    }), (0, _jsxRuntime.jsxs)(_components.tbody, {
      children: [(0, _jsxRuntime.jsxs)(_components.tr, {
        children: [(0, _jsxRuntime.jsx)(_components.td, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          })
        }), (0, _jsxRuntime.jsx)(_components.td, {
          children: "Body foreground color"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.tr, {
        children: [(0, _jsxRuntime.jsx)(_components.td, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "background"
          })
        }), (0, _jsxRuntime.jsx)(_components.td, {
          children: "Body background color"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.tr, {
        children: [(0, _jsxRuntime.jsx)(_components.td, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "primary"
          })
        }), (0, _jsxRuntime.jsx)(_components.td, {
          children: "Primary brand color for links, buttons, etc."
        })]
      }), (0, _jsxRuntime.jsxs)(_components.tr, {
        children: [(0, _jsxRuntime.jsx)(_components.td, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "secondary"
          })
        }), (0, _jsxRuntime.jsx)(_components.td, {
          children: "A secondary brand color for alternative styling"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.tr, {
        children: [(0, _jsxRuntime.jsx)(_components.td, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "accent"
          })
        }), (0, _jsxRuntime.jsx)(_components.td, {
          children: "A contrast color for emphasizing UI"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.tr, {
        children: [(0, _jsxRuntime.jsx)(_components.td, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "highlight"
          })
        }), (0, _jsxRuntime.jsx)(_components.td, {
          children: "A background color for highlighting text"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.tr, {
        children: [(0, _jsxRuntime.jsx)(_components.td, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "muted"
          })
        }), (0, _jsxRuntime.jsx)(_components.td, {
          children: "A faint color for backgrounds, borders, and accents that do not require high contrast with the background color"
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;