"use strict";

exports.__esModule = true;
exports.default = exports.Radii = exports.Buttons = exports.Badges = exports.Alerts = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
var _react2 = require("@emotion/react");
var _themeUi = require("theme-ui");
/*@jsxRuntime automatic @jsxImportSource react*/
/*original from https://github.com/beerose/theme-ui-sketchy/blob/master/src/components/components.mdx*/

const Radii = () => {
  const _components = Object.assign({
    h2: "h2"
  }, (0, _react.useMDXComponents)());
  const theme = (0, _react2.useTheme)();
  if (typeof theme.radii === 'object') {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_themeUi.Divider, {}), (0, _jsxRuntime.jsx)(_components.h2, {
        children: "Borders"
      }), (0, _jsxRuntime.jsx)(_themeUi.Flex, {
        sx: {
          flexWrap: 'wrap'
        },
        children: Object.keys(theme.radii).map(key => (0, _jsxRuntime.jsx)(_themeUi.Box, {
          p: 3,
          m: 2,
          color: "text",
          bg: "muted",
          sx: {
            border: '2px solid black',
            borderRadius: {
              key
            },
            minWidth: '100px'
          },
          children: key
        }, `border_${key}`))
      })]
    });
  }
  return null;
};
exports.Radii = Radii;
const Buttons = () => {
  const _components = Object.assign({
    h2: "h2"
  }, (0, _react.useMDXComponents)());
  const theme = (0, _react2.useTheme)();
  if (typeof theme.buttons === 'object') {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_themeUi.Divider, {}), (0, _jsxRuntime.jsx)(_components.h2, {
        children: "Buttons"
      }), (0, _jsxRuntime.jsx)(_themeUi.Flex, {
        sx: {
          flexWrap: 'wrap'
        },
        children: Object.keys(theme.buttons).map(key => (0, _jsxRuntime.jsx)(_themeUi.Button, {
          variant: key,
          m: 10,
          children: key
        }, `button_${key}`))
      })]
    });
  }
  return null;
};
exports.Buttons = Buttons;
const Badges = () => {
  const _components = Object.assign({
    h2: "h2"
  }, (0, _react.useMDXComponents)());
  const theme = (0, _react2.useTheme)();
  if (typeof theme.badges === 'object') {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_themeUi.Divider, {}), (0, _jsxRuntime.jsx)(_components.h2, {
        children: "Badges"
      }), (0, _jsxRuntime.jsx)(_themeUi.Flex, {
        sx: {
          flexWrap: 'wrap'
        },
        children: Object.keys(theme.badges).map(key => (0, _jsxRuntime.jsx)(_themeUi.Badge, {
          variant: key,
          m: 10,
          children: key
        }, `button_${key}`))
      })]
    });
  }
  return null;
};
exports.Badges = Badges;
const Alerts = () => {
  const _components = Object.assign({
    h2: "h2"
  }, (0, _react.useMDXComponents)());
  const theme = (0, _react2.useTheme)();
  if (typeof theme.alerts === 'object') {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_themeUi.Divider, {}), (0, _jsxRuntime.jsx)(_components.h2, {
        children: "Alerts"
      }), (0, _jsxRuntime.jsx)(_themeUi.Flex, {
        sx: {
          flexDirection: 'column'
        },
        children: Object.keys(theme.alerts).map(key => (0, _jsxRuntime.jsx)(_themeUi.Alert, {
          variant: key,
          m: 10,
          children: key
        }, `button_${key}`))
      })]
    });
  }
  return null;
};
exports.Alerts = Alerts;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    hr: "hr",
    h2: "h2",
    p: "p"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: ["\n", "\n", "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      id: "example-components",
      children: "Example components"
    }), "\n", (0, _jsxRuntime.jsx)(Radii, {}), "\n", "\n", (0, _jsxRuntime.jsx)(Buttons, {}), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "form-elements",
      children: "Form elements"
    }), "\n", (0, _jsxRuntime.jsxs)(_themeUi.Box, {
      as: "form",
      pb: 3,
      onSubmit: e => e.preventDefault(),
      sx: {
        width: '100%',
        maxWidth: '500px'
      },
      children: [(0, _jsxRuntime.jsx)(_themeUi.Label, {
        htmlFor: "username",
        children: "Username"
      }), (0, _jsxRuntime.jsx)(_themeUi.Input, {
        id: "username",
        mb: 3
      }), (0, _jsxRuntime.jsx)(_themeUi.Label, {
        htmlFor: "password",
        children: "Password"
      }), (0, _jsxRuntime.jsx)(_themeUi.Input, {
        type: "password",
        id: "password",
        mb: 3
      }), (0, _jsxRuntime.jsx)(_themeUi.Box, {
        children: (0, _jsxRuntime.jsxs)(_themeUi.Label, {
          mb: 3,
          children: [(0, _jsxRuntime.jsx)(_themeUi.Checkbox, {}), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Remember me"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_themeUi.Label, {
        htmlFor: "sound",
        children: "Sound"
      }), (0, _jsxRuntime.jsxs)(_themeUi.Select, {
        id: "sound",
        mb: 3,
        children: [(0, _jsxRuntime.jsx)("option", {
          children: "Beep"
        }), (0, _jsxRuntime.jsx)("option", {
          children: "Boop"
        }), (0, _jsxRuntime.jsx)("option", {
          children: "Blip"
        })]
      }), (0, _jsxRuntime.jsx)(_themeUi.Label, {
        htmlFor: "comment",
        children: "Comment"
      }), (0, _jsxRuntime.jsx)(_themeUi.Textarea, {
        id: "comment",
        rows: "6",
        mb: 3
      }), (0, _jsxRuntime.jsxs)(_themeUi.Flex, {
        mb: 3,
        children: [(0, _jsxRuntime.jsx)(_themeUi.Label, {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_themeUi.Radio, {
              name: "letter"
            }), " Alpha"]
          })
        }), (0, _jsxRuntime.jsx)(_themeUi.Label, {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_themeUi.Radio, {
              name: "letter"
            }), " Bravo"]
          })
        }), (0, _jsxRuntime.jsx)(_themeUi.Label, {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_themeUi.Radio, {
              name: "letter"
            }), " Charlie"]
          })
        })]
      }), (0, _jsxRuntime.jsx)(_themeUi.Button, {
        children: "Submit"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "links",
      children: "Links"
    }), "\n", (0, _jsxRuntime.jsx)(_themeUi.Box, {
      pb: 20,
      children: (0, _jsxRuntime.jsx)(_themeUi.Link, {
        href: "#!",
        children: "Hello"
      })
    }), "\n", "\n", (0, _jsxRuntime.jsx)(Badges, {}), "\n", "\n", (0, _jsxRuntime.jsx)(Alerts, {}), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "navigation",
      children: "Navigation"
    }), "\n", (0, _jsxRuntime.jsx)(_themeUi.Box, {
      pb: 20,
      children: (0, _jsxRuntime.jsxs)(_themeUi.Flex, {
        as: "nav",
        children: [(0, _jsxRuntime.jsx)(_themeUi.NavLink, {
          href: "#!",
          p: 2,
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "Home"
          })
        }), (0, _jsxRuntime.jsx)(_themeUi.NavLink, {
          href: "#!",
          p: 2,
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "Blog"
          })
        }), (0, _jsxRuntime.jsx)(_themeUi.NavLink, {
          href: "#!",
          p: 2,
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "About"
          })
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "table",
      children: "Table"
    }), "\n", (0, _jsxRuntime.jsxs)("table", {
      style: {
        paddingBottom: 20
      },
      children: [(0, _jsxRuntime.jsx)("thead", {
        children: (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("th", {
            colSpan: "2",
            children: "The table header"
          })
        })
      }), (0, _jsxRuntime.jsx)("tbody", {
        children: (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "The table body"
          }), (0, _jsxRuntime.jsx)("td", {
            children: "with two columns"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "card",
      children: "Card"
    }), "\n", (0, _jsxRuntime.jsx)(_themeUi.Card, {
      mb: 5,
      sx: {
        maxWidth: 400,
        padding: '30px'
      },
      children: (0, _jsxRuntime.jsx)(_themeUi.Paragraph, {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: "Cupcake ipsum dolor sit amet chocolate bar. Apple pie macaroon muffin jelly\ncandy cake soufflé muffin croissant. Gummies jelly beans cotton candy\nfruitcake. Wafer lemon drops soufflé cookie. Sesame snaps fruitcake\ncheesecake danish toffee marzipan biscuit."
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {})]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;