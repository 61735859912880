"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2",
    pre: "pre",
    code: "code",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uiprism",
      children: "@theme-ui/prism"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A syntax highlighting component based on\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/FormidableLabs/prism-react-renderer",
        children: "prism-react-renderer"
      }), "\nthat works seamlessly with Theme UI."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "installation",
      children: "Installation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "npm i @theme-ui/prism\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "usage",
      children: "Usage"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The syntax highlighting component needs to be passed to Theme UI context via the\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ThemeProvider"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// src/components/layout.js\nimport { ThemeUIProvider } from 'theme-ui'\nimport { MDXProvider } from '@mdx-js/react'\nimport { useThemedStylesWithMdx } from '@theme-ui/mdx'\nimport Prism from '@theme-ui/prism'\n\nimport theme from './theme'\n\nconst components = {\n  pre: ({ children }) => <>{children}</>,\n  code: Prism,\n}\n\nfunction Provider({ children }) {\n  const componentsWithStyles = useThemedStylesWithMdx(\n    useMDXComponents(components)\n  )\n\n  return (\n    <ThemeProvider theme={theme}>\n      <MDXProvider components={componentsWithStyles}>{children}</MDXProvider>\n    </ThemeProvider>\n  )\n}\n\nexport default Provider\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Then, all code blocks in MDX documents wrapped by Layout will be syntax\nhighlighted."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "gatsby-plugin",
      children: "Gatsby plugin"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "gatsby-plugin-theme-ui"
      }), ", shadow the\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src/gatsby-plugin-theme-ui/components.js"
      }), " module to add the Prism component to\nMDX scope."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// src/gatsby-plugin-theme-ui/components.js\nimport Prism from '@theme-ui/prism'\n\nexport default {\n  pre: (props) => props.children,\n  code: Prism,\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "syntax-themes",
      children: "Syntax themes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This package includes the default syntax color themes from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "prismjs"
      }), " and\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "prism-react-renderer"
      }), " packages. To add these to your Theme UI ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme"
      }), " object,\nimport a preset and add the styles to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.styles.code"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// theme.js\nimport nightOwl from '@theme-ui/prism/presets/night-owl.json'\n\nconst theme = {\n  // ...theme\n  styles: {\n    code: {\n      ...nightOwl,\n    },\n  },\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following themes are available and can be found in the\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/system-ui/theme-ui/tree/stable/packages/prism/presets",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "presets/"
        })
      }), "\ndirectory."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dracula.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "duotone-dark.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "duotone-light.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "github.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "night-owl-light.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "night-owl.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "oceanic-next.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "prism-coy.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "prism-dark.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "prism-funky.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "prism-okaidia.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "prism-solarizedlight.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "prism-tomorrow.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "prism-twilight.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "prism.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "shades-of-purple.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ultramin.json"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "vs-dark.json"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      id: "theme-ui-colors",
      children: "Theme UI colors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To theme the prism styles based on the colors defined in your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.colors"
      }), "\nobject, use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme-ui"
      }), " preset, which will use the following color keys for\nsyntax highlight."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "gray"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "accent"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// src/gatsby-plugin-theme-ui/index.js\nimport prism from '@theme-ui/prism/presets/theme-ui'\n\nexport default {\n  colors: {\n    text: '#000',\n    background: '#fff',\n    primary: '#33e',\n    secondary: '#119',\n    accent: '#639',\n    gray: '#666',\n  },\n  styles: {\n    code: {\n      ...prism,\n    },\n  },\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "highlight-support",
      children: "Highlight support"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This package supports highlighting inside code blocks. The following two\nsyntaxes are supported:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Single-line highlight by placing ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "// highlight-line"
      }), " at the end of the line."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "let singleLineHighlight = 'there is a // highlight-line comment here ->' // highlight-line\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Multi-line highlights by wrapping lines with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "// highlight-start"
      }), " and\n", (0, _jsxRuntime.jsx)(_components.code, {
        children: "// highlight-end"
      }), ". Multiple highlight wrappers are supported in a single code\nblock."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// highlight-start\nlet multiLineHighlight = `\n  this multiline string is wrapped by \n  // highlight-start and // highlight-end \n  comments\n`\n// highlight-end\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The highlight styling is controlled in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "styles.pre"
      }), " under ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".highlight"
      }), ". For\nexample:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\".highlight\": {\n  \"background\": \"hsla(0, 0%, 30%, .5)\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      id: "additional-languages",
      children: "Additional languages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Please note that ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@theme-ui/prism"
      }), " uses\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/FormidableLabs/prism-react-renderer",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "prism-react-renderer"
        })
      }), ",\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/vendor/prism/includeLangs.js",
        children: "which does not include all languages supported in Prism"
      }), "\nby default. So, if you happen to use one of the missing languages, your code\nblock simply won't show up as highlighted."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to add support for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "R"
      }), " language, import the language and pass a\ncustom ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Prism"
      }), " instance to the Theme UI component:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// src/gatsby-plugin-theme-ui/components.js\nimport ThemeUIPrism from '@theme-ui/prism'\nimport PrismCore from 'prismjs/components/prism-core'\nimport 'prismjs/components/prism-r'\n\nexport default {\n  pre: (props) => props.children,\n  code: (props) => <ThemeUIPrism {...props} Prism={PrismCore} />,\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;