"use strict";

exports.__esModule = true;
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      id: "theme-uistyle-guide",
      children: "@theme-ui/style-guide"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Component for rendering Theme UI styles in a style guide format."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-sh",
        children: "npm i @theme-ui/style-guide\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-jsx",
        children: "import { Themed } from '@theme-ui/mdx'\nimport { TypeScale, TypeStyle, ColorPalette } from '@theme-ui/style-guide'\n\nexport default (props) => (\n  <>\n    <Themed.h1>Style Guide</Themed.h1>\n    <ColorPalette />\n    <TypeScale />\n    <TypeStyle fontFamily=\"heading\" fontWeight=\"heading\" lineHeight=\"heading\" />\n    <TypeStyle fontFamily=\"body\" fontWeight=\"body\" lineHeight=\"body\" />\n  </>\n)\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["See the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "gatsby-theme-style-guide"
      }), " package for easier integration with Gatsby."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;