import verticalRhythm from 'compass-vertical-rhythm';
import '@theme-ui/css';
import ms from 'modularscale';

// theme.styles object for use with typography.js-generated theme object
// similar to typography.js style output, with these differences
// - only includes styles for markdown elements
// - does not include color styles
// - does not include responsive styles

const heading = {
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading'
};
const baseStyles = {
  root: {
    fontFamily: 'body',
    fontSize: 2,
    fontWeight: 'body',
    lineHeight: 'body'
  },
  img: {
    maxWidth: '100%'
  },
  h1: {
    fontSize: 5,
    ...heading
  },
  h2: {
    fontSize: 4,
    ...heading
  },
  h3: {
    fontSize: 3,
    ...heading
  },
  h4: {
    fontSize: 2,
    ...heading
  },
  h5: {
    fontSize: 1,
    ...heading
  },
  h6: {
    fontSize: 0,
    ...heading
  },
  ul: {
    listStylePosition: 'outside',
    listStyleImage: 'none',
    ml: 3
  },
  ol: {
    listStylePosition: 'outside',
    listStyleImage: 'none',
    ml: 3
  },
  li: {
    mb: 2,
    pl: 0,
    ol: {
      my: 2,
      ml: 3
    },
    ul: {
      my: 2,
      ml: 3
    },
    p: {
      mb: 2
    }
  },
  p: {},
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  th: {
    textAlign: 'left',
    borderBottom: '1px solid',
    px: 2,
    py: 1,
    ':first-child': {
      pl: 0
    },
    ':last-child': {
      pr: 0
    }
  },
  td: {
    textAlign: 'left',
    borderBottom: '1px solid',
    px: 2,
    py: 1,
    mt: '-1px',
    ':first-child': {
      pl: 0
    },
    ':last-child': {
      pr: 0
    }
  },
  blockquote: {
    mx: 3
  },
  hr: {
    border: 0,
    borderBottom: '1px solid',
    mt: '-1px',
    mb: 3
  },
  b: {
    fontWeight: 'bold'
  },
  strong: {
    fontWeight: 'bold'
  },
  code: {
    fontSize: '85%'
  },
  pre: {
    fontSize: '85%',
    padding: 3
  }
};
const headings = ['h6', 'h5', 'h4', 'h3', 'h2', 'h1'];
const blockElements = [...headings, 'ul', 'ol', 'p', 'pre', 'table', 'blockquote', 'img', 'hr'];
const styles = {
  ...baseStyles,
  ...blockElements.reduce((style, tag) => ({
    ...style,
    [tag]: {
      padding: 0,
      margin: 0,
      marginBottom: 3,
      ...baseStyles[tag]
    }
  }), {})
};

// custom implementation of typography.js for use in theme-ui
const unwantedTypographyOptions = ['headerColor', 'bodyColor', 'overrideStyles', 'overrideThemeStyles', 'plugins'];
// - uses unitless values
// - creates base theme object
// - uses a static theme.styles object for consumption in theme-ui
// - ignores overrideThemeStyles
// - does not include color styles
// - should be mostly compatible with existing typography.js themes

const defaults = {
  baseFontSize: 16,
  baseLineHeight: 1.45,
  headerLineHeight: 1.1,
  scaleRatio: 2,
  googleFonts: [],
  headerFontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'],
  bodyFontFamily: ['georgia', 'serif'],
  headerWeight: 'bold',
  bodyWeight: 'normal',
  boldWeight: 'bold',
  includeNormalize: true,
  blockMarginBottom: 1
};
const toUnitless = parseFloat;
const getScale = opts => value => ms(value, opts.scaleRatio) * opts.baseFontSize;
const getSpace = (rhythm, opts) => {
  const n = toUnitless(rhythm.rhythm(opts.blockMarginBottom));
  return [0, 1 / 4, 1 / 2, 1, 2, 4, 8].map(v => v * n);
};

// genericFontFamilies, wrapFontFamily adapted from typography.js
// Wrap font names in quotes, unless the font name is actually a keyword.
// See https://stackoverflow.com/a/13752149 and https://www.w3.org/TR/CSS2/fonts.html#font-family-prop
const genericFontFamilies = ['inherit', 'default', 'serif', 'sans-serif', 'monospace', 'fantasy', 'cursive', '-apple-system', 'system-ui'];
const wrapFontFamily = fontFamily => genericFontFamilies.includes(fontFamily) ? fontFamily : `'${fontFamily}'`;
const stackFonts = fonts => fonts.map(wrapFontFamily).join(', ');
const getFonts = (rhythm, opts) => {
  const body = stackFonts(opts.bodyFontFamily);
  const heading = stackFonts(opts.headerFontFamily);
  return {
    body,
    heading
  };
};
const getFontSizes = (rhythm, opts) => {
  const scale = getScale(opts);
  return [-1.5 / 5, -1 / 5, 0, 2 / 5, 3 / 5, 1].map(scale);
};
const getLineHeights = (rhythm, opts) => {
  const body = opts.baseLineHeight;
  const heading = opts.headerLineHeight;
  return {
    body,
    heading
  };
};
const getFontWeights = (rhythm, opts) => {
  return {
    body: opts.bodyWeight,
    bold: opts.boldWeight,
    heading: opts.headerWeight
  };
};
const pruneOptionsFromUnwanted = opts => {
  if (opts == null) {
    return opts;
  }
  const res = {
    ...opts
  };
  for (const k of unwantedTypographyOptions) {
    delete res[k];
  }
  return res;
};
const toUnitlessOptions = opts => {
  // Return nullish opts
  // Or opts with nullish baseFontSize (intentional override)
  // Or opts with unset baseFontSize (just not defined)
  if (opts == null || opts.baseFontSize == null) {
    return opts;
  }
  return {
    ...opts,
    baseFontSize: toUnitless(opts.baseFontSize)
  };
};

// We can say more about the theme received from `toTheme` than about
// unknown generic theme.

const toTheme = options => {
  const opts = {
    ...defaults,
    // remove unwanted options
    ...toUnitlessOptions(
    // enforce unitless values
    pruneOptionsFromUnwanted(options))
  };
  const rhythmOpts = {
    ...opts,
    rhythmUnit: 'px',
    baseFontSize: String(opts.baseFontSize)
  };
  const rhythm = verticalRhythm(rhythmOpts);
  return {
    space: getSpace(rhythm, opts),
    fonts: getFonts(rhythm, opts),
    fontSizes: getFontSizes(rhythm, opts),
    fontWeights: getFontWeights(rhythm, opts),
    lineHeights: getLineHeights(rhythm, opts),
    styles,
    typography: {
      ...rhythm,
      options: opts
    }
  };
};

export { styles, toTheme };
